@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css);
@font-face {
  font-family: 'Avenir';
  src: url(/static/media/AvenirNextLTPro-Regular.b5ed9330.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url(/static/media/AvenirNextLTPro-Regular.b5ed9330.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

body{min-height: 100vh !important; }

body, p, h1, h2, h3, h4, h5, h6, li, a, span, button, b, html, .card-title, .report-title, .tag, .meetingCompanyNameColumn, .ant-steps-item-title, div{
  font-family: 'Avenir', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container{width: 100% !important; margin: auto !important;}

.bg-white{background-color: white}

.ant-btn-primary, .ant-btn-primary:hover{
  background-color: #F2C425 !important;
  color: #4b4b4b;
}
.text-gold{color: #F2C425 !important;}
.btn{
  min-width: 80px !important;
}

.btn-white{
  background-color: #FFF !important;
  border: 1px solid #FFF !important;
  color: black;
  min-width: 200px !important;
}

.btn-white:hover{
  background-color: #F2C425 !important;
  border: 1px solid #F2C425 !important;
  color: #4b4b4b;
}

.btn-primary, .btn-primary:hover{
  background-color: #F2C425 !important;
  border: 2px solid #4b4b4b !important;
  color: #4b4b4b;
}

.btn-primary-outline{
  background-color: transparent;
  border: 1px solid #F2C425 !important;
  color: #F2C425;
}

.btn-primary-outline:hover{
  background-color: #F2C425;
  border: 1px solid #F2C425 !important;
  color: #4b4b4b;
}

.btn-primary-disabled{
  background-color: #c1c1c1 !important;
  border: 1px solid #ccc !important;
  color: #FFF !important;
  font-weight: bold;
}

.btn-dark{
  background-color: #373333 !important;
  border: 1px solid #373333 !important;
  color: #F2F2F2;
}

.btn-small{font-size: 12px; font-weight: bold}

.card-radius{border-radius: 10px}

.initials-avatar{
  width: 40px !important;
  height: 40px !important;
  background-color: #F2C425 !important;
  font-weight: bold;
}
.pricing-card{min-height: 55vh !important;}
.pricing-header{
  font-weight: bolder;
  font-size: 1.6rem;
}
.pricing-header-sub{font-size: 12px}
.pricing-list{font-size: 13px}
.pricing-detail{min-height: 20vh}

input[type="checkbox"], .ant-checkbox-wrapper {
  padding: 10px !important;
  font-size: 1rem !important;
}

/*==== Recommendation Styles ======*/
.bg-light-blue{background-color: #f0ffff}
.bg-light-yellow {
  background-color: #fff9ea
}
.max-height{min-height: 80vh}
.badge-success{color: #343e3f; background-color: #caebf1; padding: 0; font-size: 11px}

.dashboard-card{
  min-height: 38vh;
  border-radius: 15px;
}

.dashboard-card-text{min-height: 18vh}

.bg-profile-gradient{
  height: 20px;
  background-color: #00b0e8;
}

.nav-item .active{
  color: #F2C425 !important;
  font-weight: bolder;
}

.profile-sections-box-detail{
  min-height:10vh;
}

.profile-avatar-box{
  width: 150px !important;
  height: 150px !important;
  background-color: #00b0e8;
  border-radius: 50%;
  text-align: center;
}

.icon-large{font-size: 2rem}

.center-text-div {
  text-align: center;
  padding-top: 20%;
}

.file-grey-box{
  background-color: #FAFAFA;
  border-radius: 20px;
}

.dot-small-card{
  height: 25px;
  width: 25px;
  background-color: #4b4b4b;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
}

.number-circle-active{
  height: 25px;
  width: 25px;
  background-color: #6bc497;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
  margin-right: 10px;
  font-size: 13px;
  padding-top: 3px;
}

.number-circle{
  height: 25px;
  width: 25px;
  /*background-color: #6bc497;*/
  border-radius: 50%;
  color: black;
  text-align: center;
  margin-right: 10px;
  font-size: 13px;
  padding-top: 3px;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-otp{width: 100%}

.ant-select{padding: 0 !important;;background-color: transparent !important;border: none !important;width: 100% !important;}
.react-tel-input > .form-control{width: 100% !important;}


@media print {
  .no-print { display: none !important; }  /* Hide buttons during PDF generation */
}

.bg-recentMessage {
  background-color: #00b0e8;
  border-radius: 20%;
  color: #FFF;
  padding: .5rem 1rem !important
}

.text-small {
  font-size: 0.7rem;
}

.text-small-message {
  font-size: 0.9rem;
}

.message-width {
  width: 90%;
  height: 80vh;
}

.message-header {
  height: 8vh;
  width: 100%;
}

.message-content {
  height: 65vh;
  width: 100%;
  overflow: scroll;
  scroll-behavior: smooth;
}

.message-actions {
  height: 12vh;
  border: 2px solid #EEE;
  border-radius: 20px;
}

.message-content::-webkit-scrollbar {
  width: 5px;
}

.message-content::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.message-card-right {
  background-color: #EEE;
  text-align: right;
  border-radius: 10px;
  width: 50%;
  margin: 0 0 0 50%;
}

.message-card-left {
  border: 2px solid #EEE;
  text-align: left;
  border-radius: 10px;
  width: 50%;
}

#message-actions-textarea {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  background: transparent;
  resize: none;
  box-shadow: none !important;
  font-family: inherit;
  font-size: inherit;
  width: 100%;
}

.chat-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 5px;
}

.chat-input i {
  font-size: 17px;
  cursor: pointer;
  color: #555;
}

.chat-input i:hover {
  color: #000;
}

.text-blue {
  color: rgb(100, 131, 201);
}

.suggestedProgress .ant-progress-inner {
  width: 50px !important;
  height: 50px !important;
  font-size: 13px !important;
}

/* Remove default Bootstrap input focus styles and set custom yellow border */
.form-control {
  font-size: 0.9rem;
}

.form-control:focus,
.form-select:focus,
textarea:focus {
  border-color: #F2C425 !important;
  box-shadow: 0 0 0 0.08rem rgba(242, 196, 37, 0.5) !important;
  outline: none !important;
  font-size: 0.9rem;
}

label {
  font-size: 0.9rem;
}

@media only screen and (max-width: 600px) {
  .max-height{min-height: auto}
  .mobile-full-width{width: 100% !important;}
  .mobile-padding-top{padding-top: 8% !important;}

  .bg-recentMessage {display: none; }
  
  .chat-input { margin-top: -15px }
}
/* Dashboard.css */
/* .navbar-fixed {
    height: 50px;
    position: fixed;
    width: 100%; 
    z-index: 1000;
}

.main-screen-container {
    margin-top: '50px';
    overflow-y: 'auto';
    flex: 1;
} */

.completePageContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.mainContentBelowTheNavbar {
    margin-top: 45px;
    /* Adjust according to the Navbar height */
    padding: 20px;
    overflow-y: auto;
    height: calc(100vh - 45px);
    /* Adjust according to the Navbar height */
}
.menu-item-selected {
  border-bottom: 4px solid #F2C41E !important;
  color: black !important;
  /* Ensures the text color is black for selected tab */
  font-weight: bold;
}

.user-profile-menu-item {
  font-weight: bold;
  color: black !important;
}

.navbar-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  line-height: 64px;
  border-bottom: none;
  box-shadow: 0 2px 8px #f0f1f2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  /* flex-wrap: nowrap;  */
  margin-bottom: 1rem;
}

.logo-item {
  display: flex;
  align-items: center;
  height: 64px;
}

.logo-img {
  margin-right: 140px;
  height: 40px;
}

.mailIconContainer {
  display: flex;
  align-items: center;
  height: 50px;
}

.mailIcon {
  height: 30px;
}

.menu-item {
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.menu-divider {
  padding: 0 1px;
}

.divider-line {
  height: 50px;
  width: 1px;
  background-color: black;
}

.userType {
  color: black;
  font-size: 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.center-menu-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-shrink: 0;
  white-space: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.center-menu {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  white-space: nowrap;
}

.right-menu-container {
  display: flex;
  flex-grow: 0.05;
  justify-content: center;
  flex-shrink: 0;
  white-space: nowrap;
  flex-wrap: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.right-menu-item {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  white-space: nowrap;
  flex-wrap: nowrap;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
}

/* Styles for dropdown menu items */
.dropdown-menu {
  min-width: 180px; /* Adjust based on the width of the user menu */
}

.dropdown-menu-item {
  font-size: 1rem;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
}

.dropdown-menu-item:hover {
  background-color: #f0f1f2;
  cursor: pointer;
}

/* Override Ant Design default styles */
.ant-menu-horizontal {
  border-bottom: none !important;
}

.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom: none !important;
}

/* Change the hover underline color */
.ant-menu-horizontal
  > .ant-menu-item:hover:not(.ant-menu-item-selected)::after {
  border-bottom: 1px solid lightgrey !important;
  /* border-bottom: none !important; */
  /* Change the color here */
}

/* Override cursor style for disabled menu items */
.ant-menu-item-disabled {
  cursor: default !important;
  pointer-events: none !important; /* Prevent clicks */
  opacity: 1 !important; /* Ensure full opacity */
  color: inherit !important; /* Ensure text color is inherited */
}

.assessmentFormHeaderTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    width: 100%;
    max-width: 37%;
    margin: 0 auto;
  }
  
.progress-bar {
    width: 100%;
    margin-top: 3.5%;
}
.section-info {
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.section-name {
    float: left;
}

.section-progress {
    float: right;
}
/* imported default Ant Design button styles from the online documentation */
.ant-btn {
    display: inline-block;
    font-weight: 400;
    color: #000000d9;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-user-select: none;
            user-select: none;
    touch-action: manipulation;
    height: 30px;
    padding: 4px 15px;
    font-size: 16px;
    border-radius: 2px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.save-exit-btn {
    /* color: black; */
    position: absolute;
    top: 5%;
    right: 20px;
    font-size: 1em;
    /* padding: 10px 20px; */
    /* line-height: 1.5;  */
    /* Ensure text is centered vertically */
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 35%;
    /*width: 11%;*/
    transition: box-shadow 0.3s ease;
    font-weight: bold;
}

.save-exit-btn:hover {
    color: #fff !important;
    background-color: #F2C41E !important;
    border-color: #F2C41E !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
}

.previousButton {
    display: inline-block;
    font-weight: 400;
    color: #000000d9;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-user-select: none;
            user-select: none;
    touch-action: manipulation;
    height: 32px;
    /* padding: 4px 15px; */
    font-size: 16px;

    position: absolute;
    right: 20px;
    font-size: 1em;
    padding: 25px 20px;
    /* line-height: 1.5;  */
    /* Ensure text is centered vertically */
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    width: 11%;
    transition: box-shadow 0.3s ease;
    font-weight: bold;

    margin-left: 20px;
    margin-right: 2rem;
}
.assessmentFooter {
  width: 70%;
  margin: auto;
  display: block;
  position: fixed;
  bottom: 2rem;
}
.assessmentQuestion {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    margin-bottom: 3% !important;
    text-align: center;
    width: 62vw;
    max-width: 100vw;
    margin: 0 auto;
}
.radio-group-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block-start: auto;
}

.radio-group {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    text-align: center; /* Ensures text inside the radio group is centered */
    text-align: left; /* Ensures text inside the radio group is aligned left */
    width: 10vw; /* Takes up full width to ensure centering */
    max-width: 100vw; /* Optional: Limits the width of the radio group for better readability */
}

.radio-group .ant-radio-wrapper {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    width: 50vw; /* Takes up full width to ensure centering */
    white-space: nowrap;
}

.radio-group .ant-radio-wrapper .ant-radio + span {
    margin-left: 30px; 
    /* Adjust the value as needed */
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

/* Custom styles for the radio button */
.radio-group .ant-radio-inner {
    background-color: #D9D9D9; /* Set the background color of the radio button */
}

.radio-group .ant-radio-checked .ant-radio-inner {
    background-color: #F2C41E; /* Ensure the background color stays #D9D9D9 when checked */
    border-color: #D9D9D9; /* Set the border color to #D9D9D9 */
}

/* Set the hover and focus border color to #D9D9D9 */
.radio-group .ant-radio:hover .ant-radio-inner,
.radio-group .ant-radio-input:focus + .ant-radio-inner {
    border-color: #D9D9D9; 
}
/*!* Ant Design Checkbox Styles *!*/
/*.ant-checkbox-wrapper {*/
/*    !* display: inline-block; *!*/
/*    margin: 0 auto;*/
/*    font-size: 16px;*/
/*    line-height: 1.5;*/
/*    cursor: pointer;*/
/*    font-family: 'AvenirNextLTPro', sans-serif !important;*/
/*    font-weight: 500;*/
/*    width: 10vw; !* Takes up full width to ensure centering *!*/
/*    max-width: 100vw; !* Optional: Limits the width of the radio group for better readability *!*/
/*    white-space: nowrap;*/
/*}*/

/*.ant-checkbox {*/
/*    top: 0;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    line-height: 1;*/
/*    white-space: nowrap;*/
/*    vertical-align: middle;*/
/*    cursor: pointer;*/
/*    -webkit-transition: all 0.3s;*/
/*    transition: all 0.3s;*/
/*}*/

/*.ant-checkbox-inner {*/
/*    position: relative;*/
/*    top: 0;*/
/*    left: 0;*/
/*    display: block;*/
/*    width: 20px; !* Increased width *!*/
/*    height: 20px; !* Increased height *!*/
/*    direction: ltr;*/
/*    border: 1px solid grey;*/
/*    -webkit-transition: all 0.3s;*/
/*    transition: all 0.3s;*/
/*}*/

/*.ant-checkbox-checked .ant-checkbox-inner {*/
/*    background-color: #F2C41E !important;*/
/*    border-color:#D9D9D9;*/
/*}*/

/*.ant-checkbox-checked .ant-checkbox-inner:after {*/
/*    position: absolute;*/
/*    display: table;*/
/*    border: 2px solid #fff;*/
/*    border-top: 0;*/
/*    border-left: 0;*/
/*    content: ' ';*/
/*    height: 6px;*/
/*    left: 4px;*/
/*    top: 2px;*/
/*    width: 3px;*/
/*    -webkit-transform: rotate(45deg) scale(1);*/
/*    transform: rotate(45deg) scale(1);*/
/*    -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.29, 1.46) 0.1s;*/
/*    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.29, 1.46) 0.1s;*/
/*}*/

/*.ant-checkbox-wrapper+.ant-checkbox-wrapper {*/
/*    margin-left: 8px;*/
/*}*/

/*.ant-checkbox-checked::after {*/
/*    position: absolute;*/
/*    display: block;*/
/*    content: "";*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border: 1px solid #1890ff;*/
/*    !* border-radius: 2px; *!*/
/*    visibility: hidden;*/
/*    animation: antCheckboxEffect 0.36s ease-in-out;*/
/*    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;*/
/*}*/

/*@keyframes antCheckboxEffect {*/
/*    0% {*/
/*        transform: scale(1);*/
/*        opacity: 1;*/
/*    }*/

/*    100% {*/
/*        transform: scale(1.6);*/
/*        opacity: 0;*/
/*    }*/
/*}*/

/*@-webkit-keyframes antCheckboxEffect {*/
/*    0% {*/
/*        -webkit-transform: scale(1);*/
/*        transform: scale(1);*/
/*        opacity: 1;*/
/*    }*/

/*    100% {*/
/*        -webkit-transform: scale(1.6);*/
/*        transform: scale(1.6);*/
/*        opacity: 0;*/
/*    }*/
/*}*/


/*!* Custom Styles *!*/
/*.container {*/
/*    !*padding: 20px;*!*/
/*}*/

/*.checkbox-wrapper {*/
/*    display: flex !important;*/
/*    align-items: center;*/
/*    !* margin-bottom: 16px; *!*/
/*    flex-direction: column;*/
/*}*/

/*.checkbox-wrapper label {*/
/*    margin-left: 8px;*/
/*    font-size: 14px;*/
/*}*/

/*.checkbox-wrapper .ant-checkbox + span {*/
/*    margin-left: 25px; !* Add space between the checkbox and the label *!*/
/*}*/

/*.ant-checkbox-inner {*/
/*    width: 20px !important; !* Increased width *!*/
/*    height: 20px !important; !* Increased height *!*/
/*    background-color: #D9D9D9 !important;*/
/*    !* border-radius: 5px !important;  *!*/
/*    !* Custom border radius *!*/
/*}*/

/*.ant-checkbox-checked .ant-checkbox-inner::after {*/
/*    left: 6px !important; !* Adjusted left position *!*/
/*    top: 2px !important; !* Adjusted top position *!*/
/*    width: 6px !important; !* Adjusted width *!*/
/*    height: 10px !important;  !* Adjusted height *!*/
/*    border: solid white !important;*/
/*    border-width: 0 2px 2px 0 !important;*/
/*    transform: rotate(45deg) !important;*/
/*}*/
body {
    margin: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; 
    font-size: 16px;
    display: flex;
    flex-direction: column;
}

.dropdown-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
}

.ant-select {
    width: 45vw; /* 20% of the viewport width */
    padding: 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #F5F5F5;
    transition: all 0.3s;

    appearance: none; /* Remove default arrow */
    -webkit-appearance: none; /* Remove default arrow in Safari */
    -moz-appearance: none; /* Remove default arrow in Firefox */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 34px 24px;
}

.ant-select:hover {
    border-color: #F5F5F5;
}

.ant-select:focus {
    border-color: #F5F5F5;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.button-container {
    margin-top: 10px; /* Add some space between the dropdown and the button */
    width: 100%; /* Ensure the button takes full width if needed */
    display: flex;
    justify-content: center; /* Center the button */
}
.shortAnswerWithDropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* Ensure it takes the full width of its parent */
}

.short-answer-text-container {
    width: 25%;
    /* Allocate 75% width to the text input container */
}

.dropdown-container-with-short-text {
    width: 15%;
    /* Allocate 20% width to the dropdown container */
    display: flex;
    flex-direction: row;
    /* Align the dropdown horizontally */
    justify-content: center;
    align-items: center;
}
.questionAndAnswerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}
/* Custom Styles Written for the common button to be shown with the Yellow Background Color */ 
.continueButtonForAssessmentForm {
    color: white;
    width: 17vw;
    background-color: #F2C41E !important; /* b. No fill background color */
    border-color: #F2C41E !important; /* c. Light yellow border color */
    border-radius: 30px !important; /* a. Rounded corner edges */
    padding: 20px !important; /* Wider button with minimal height */
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;

    font-weight: bold;
    /* color: #000000d9; */
    /* text-align: center; */
    /* background-color: #fff; */
    /* border: 1px solid #d9d9d9; */
    cursor: pointer;
    transition: all 0.3s;
    -webkit-user-select: none;
            user-select: none;
    touch-action: manipulation;
    /* height: 32px; */
    font-size: 16px;
    /* border-radius: 2px; */
    margin-top: 2%;
}

.continueButtonForAssessmentForm:hover {
    color: #fff !important;
    background-color: #F2C41E !important;
    border-color: #F2C41E !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
}
/* Custom Styles */
.errorContainer {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: red;
    padding-top: 10px;
}
.completePageContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.mainContentBelowTheNavbar {
    margin-top: 45px;
    /* Adjust according to the Navbar height */
    padding: 20px;
    overflow-y: auto;
    height: calc(100vh - 45px);
    /* Adjust according to the Navbar height */
}
/* Parent container that centers all items */
.no-mails-header-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center;  */
    /* Center items vertically */
    align-items: center; /* Center items horizontally */
    height: 100vh; /* Full height of the viewport */
    text-align: center;
    margin-top: 5%;
  }
  
  /* Styles for the image */
  .header-image {
    width: 165px;
    height: 165px;
    margin-bottom: 20px;
  }

  .no-data-image {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  
  /* Styles for the title */
  .header-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Styles for the subtitle */
  .header-subtitle {
    font-size: 16px;
    color: #8899A6;
    margin-bottom: 20px;
    width: 27%;
    font-weight: 400;
  }
  
  /* Styles for the button */
  .header-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #F2C41E;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .header-button:hover {
    /* background-color: yellow; */
  }
  
.investmentReadinessContent {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    /* text-align: justify !important;
    hyphens: auto !important;
    overflow-wrap: break-word;  */
    /* Move long words to the next line */
    /* word-break: keep-all;       */
    /* Prevent word breaks */
}

.removekeysmargintop {
    margin-top: -15px;
}

.companyOverviewSectionContainer {
    margin-top: 30px;
}

.investmentReadinessSectionTitle {
    font-weight: 700;
    font-size: 40px;
    line-height: 39px;
    color: #4B4B4B;
}

.investmentReadinessCompanyName {
    font-weight: 400;
    font-size: 40px;
    line-height: 39px;
    color: #4B4B4B;
    padding-top: 5px;
}

.investmentReadinessSectionBottomLine {
    margin-top: 10px;
    border-bottom: 0.5px solid #CACACA
}

.locationDetails {
    display: flex;
    align-items: center;
    /* Vertically centers the image and text */
    margin-top: 15px;
}

.locationText,
.addressDetailsText {
    margin-left: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    display: flex;
    align-items: center;
}

.addressDetails {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.industryBussinessActivityMissionContent {
    border-left: 4px solid #47B881;
    padding-left: 10px;
    margin-top: 50px;
}

.industryContent,
.businessActivityContent {
    display: flex;
}

.industryKey,
.businessActivityKey,
.missionKeyAndValue,
.blockContentText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
}

.induistryValue,
.businessyActivityValue {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    margin-left: 5px;
}

.missionContent {
    display: flex;
    margin-top: 45px;
}

.twoByTwoSectionDisplay {
    margin-top: 50px;
}

.rowOneContent,
.rowTwoContent {
    display: flex;
}

.rowTwoContent {
    margin-top: 40px;
}

.blockOne,
.blockThree {
    width: 35%;
}

.blockTwo {
    width: 10%;
}

.titleAndIcon {
    display: flex;
    /* align-items: center; */
}

.blockTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #4B4B4B;
    margin-left: 10px;
}

.investmentReadinessSectionDivider {
    border-top: 1px solid #CACACA;
    width: 50%;
    margin-top: 10%;
}

.investmentReadinessMarketOpportunitySection {
    margin-top: 3%;
}

.investmentReadinessMarketOpportunitySectionHeader {
    display: flex;
}

.investmentReadinessMarketOpportunitySectionTitleIndex {
    font-weight: 700;
    font-size: 40px;
    line-height: 39px;
    color: #E1E1E1;
}

.investmentReadinessMarketOpportunitySectionTitleName {
    margin-left: 15px;
    font-weight: 700;
    font-size: 40px;
    line-height: 39px;
    color: #4B4B4B;
}

.investmentReadinessRecommendationsSectionTitleName {
    font-weight: 700;
    font-size: 40px;
    line-height: 39px;
    color: #4B4B4B;
}

.investmentReadinessMarketOpportunitySectionContent {
    margin-top: 7%;
    display: flex;
    flex-direction: row;
}

.nonGraphicalContentForMarketOpportunity,
.graphicalComponentForMarketOpportunity {
    width: 35%;
}

.gapSection {
    width: 10%;
}

.nonGraphicalContentForMarketOpportunity {
    border-left: 4px solid #47B881;
    padding-left: 20px;
    height: -moz-fit-content;
    height: fit-content;
}

.analysisContentTitle,
.rankingContentTitle {
    display: flex;
    align-items: center;
}

.analsyisTitleName,
.rankingTitleName {
    margin-left: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24.59px;
    color: #000000;
}

.analysisContentDescription {
    margin-top: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
}

.rankingContentForMarketOpportunity {
    margin-top: 10%;
}

.tamContentSectionLine,
.samContentSectionLine {
    display: flex;
}

.rankingContentDescription {
    margin-top: 10px;
}

.tamKey,
.samKey,
.samKeyValue,
.tamKeyValue,
.averageValue,
.belowAverageValue {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.tamKey,
.samKey {
    color: #1F1F1F;
}

.samKeyValue,
.tamKeyValue {
    color: #F64C4C;
    margin-left: 5px;
}

.outer-circle {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #BDDDFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.inner-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #E5F5EC;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    margin-top: 90px;
}

.outerText {
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #1F1F1F;
}

.subOuterTextPartTwo {
    font-weight: 600 !important;
}

.innerText {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #1F1F1F;
    text-align: center;
}

.subInnerTextPartTwo {
    font-weight: 600 !important;
    color: #4B4B4B !important;
}

.averageValue {
    color: #FE9B0E;
}

.keyInformationSectionLine {
    display: block;
    /* Make the container block-level */
    width: 100%;
    /* Let the container take full width */
}

.keyDifferentiatorsKey {
    display: inline-block;
    /* Allow the label to stay inline with the content */
    white-space: nowrap;
    /* Prevent the label from wrapping */
    margin-right: 5px;
    /* Add a small gap between label and content */
    font-weight: 700;
}

.keyDifferentiatorsValue {
    display: inline;
    /* Keep the value inline with the label */
    word-break: break-word;
    /* Break the text only if it's too long */
}

.someAnalysisImage {
    margin-top: 45px;
}

.belowAverageValue {
    color: #F64C4C;
}

.financialMetricsDetailsSection {
    margin-top: 50px;
    margin-left: 20px;
}

.financialMetricName {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1F1F1F;
}

.financialMetricValue {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #1F1F1F;
}

.financialMetricsDetailsSectionOne,
.financialMetricsDetailsSectionTwo {
    display: flex;
}

.financialMetricOne,
.financialMetricTwo,
.financialMetricThree {
    width: 20%;
}

.gapBetweenFinancialMetrics {
    width: 5%;
}

.financialMetricsDetailsSectionTwo {
    margin-top: 40px;
}

.recommendationsSectionRowOne,
.recommendationsSectionRowTwo,
.recommendationsSectionRowThree,
.recommendationsSectionRowFour {
    display: flex;
}

.recommendationsSectionRowTwo,
.recommendationsSectionRowThree,
.recommendationsSectionRowFour {
    margin-top: 30px;
}

.recommendationsSectionBlockContent {
    width: 28%;
}

.gapBetweenRecommendationSectionBlocks {
    width: 8%;
}

.recommendationsSectionBlockContentTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    display: flex;
}

.recommendationsSectionBlockContentTitleName {
    margin-left: 5px;
}

.recommendationsSectionBlockContentDescription {
    margin-top: 10px;
    text-align: justify;
    /* font-weight: 500; */
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.investmentReadinessConclusionSectionContent {
    margin-top: 30px;
}

.conclusionTextForInvestmentReadiness {
    text-align: justify;
    /* font-weight: 500; */
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.contactInformationSectionDividerInvestmentReadiness {
    margin-top: 10%;
}

.contactInformationTitle {
    text-align: justify;
    /* font-weight: 500; */
    font-size: 20px;
    line-height: 32px;
    color: #4B4B4B;
}

.contactInformationDetails {
    margin-top: 10px;
    color: #1F1F1F;
}
.executableSolutionsContent {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    /* text-align: justify !important; */
    -webkit-hyphens: auto !important;
            hyphens: auto !important;
    overflow-wrap: break-word; /* Move long words to the next line */
    word-break: keep-all;      /* Prevent word breaks */
}

.accelerateUserGrowthSection {
    margin-top: 30px;
}

.executable-solutions-section-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #4B4B4B;
}


.executable-solutions-section-title-bottom-line {
    margin-top: 5px;
    border-bottom: 0.5px solid #CACACA
}

.stratergiesTitleText {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24.59px;
    color: #4B4B4B;
}

.stratergySectionHeader {
    margin-top: 5px;
}

.stratergy-section-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    background-color: #FFFBD1;
    padding: 8px;
    display: flex !important;
    align-items: center !important;
}

.stratergySectionContent {
    margin-top: 15px;
}

.stratergySectionContentContainer {
    display: flex;
    align-items: flex-start;
    /* Align items to the top */
    grid-gap: 10px;
    gap: 10px;
    /* Optional: Adds space between the button and text */
    padding-left: 8px;
}

.subsequentStratergySectionContentContainer {
    margin-top: 20px;
}

.stratergySectionButtonWrapper {
    display: flex;
    justify-content: center !important;
    /* Horizontally center the button */
    align-items: center !important;
    /* Vertically center the button */
}

.stratergySectionContentRoundButton {
    width: 9px;
    height: 8px;
    background-color: black;
    border-radius: 50%;
    /* Make it a perfect circle */
    align-items: center !important;
    justify-content: center !important;
    flex-shrink: 0;
    /* Prevents the button from shrinking */
    margin-top: 8px;
}


.stratergySectionTextContent {
    flex: 1 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.12px;
    color: #000000;
}

.executableSectionContainer {
    background: #F8FCFF;
    margin-top: 40px;
    padding-top: 30px;
    padding-left: 15px;
    border-radius: 10px;
    padding-bottom: 20px;
}

.executableSectionHeader {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #3A70E2;
}

.problemContainer,
.solutionContainer {
    background-color: #FFFFFF;
    padding: 10px;
    width: 40%;
    height: -moz-fit-content;
    height: fit-content;
}

.problemSolutionContainer {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 30px;
}

.problemTitle,
.solutionTitle {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
}

.problemTitle {
    color: #F64C4C;
}

.solutionTitle {
    color: #47B881;
}

.problemDescription,
.solutionDescription {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    padding-bottom: 20px;
} 

.actionableStepsContentContainer {
    margin-bottom: 20px;
}

.actionableStepsTitleContainer {
    margin-top: 40px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.actionableStepsTitleText {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1F1F1F;
}

.executableSolutionsCardContainer {
    background: #FFFFFF;
    padding: 10px;
    margin-top: 20px;
    width: 85%;
    border-radius: 10px;
}

.actionableStepTextDescriptionPoint,
.actionableStepArrowIcon {
    padding: 10px;
}

.actionableStepTextDescriptionPoint {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F; 
}
.table-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 50px;
  }
  
  .ranking-table {
    width: 90%;
    border-collapse: collapse;
    border: 1px solid #BDDDFF
  }
  
  .ranking-table th, .ranking-table td {
    padding: 15px 20px;
    border: 1px solid #BDDDFF;
    text-align: center;
    border-radius: 10px;
  }
  
  .ranking-table th {
    background-color: #FFFFFF;
    font-weight: 500;
    font-size: 18px;
    line-height: 24.59px;
    color: #3A70E2;
  }

  .ranking-table tbody {
    background-color: #FFFFFF;
  }
  
  .badge {
    padding: 10px 30px;
    border-radius: 12px;
  }
  
  .below-average {
    /* background-color: #f66; */
    border: 0.5px solid #F64C4C;
    background: #FFFBFB;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #F64C4C;
  }
  
  .average {
    /* background-color: #ffc107; */
    border: 0.5px solid #FE9B0E;
    background: #FFF9E8;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FE9B0E;
  }
  
  .rowAspectInTable {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    text-align: left !important;
  }

  .header-icon {
    width: 18px;  /* Adjust size as needed */
    height: 18px;
    margin-right: 8px;  /* Adds space between the image and text */
    vertical-align: middle;  /* Ensures the image is vertically aligned */
  }
.circular-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circular-progress {
    --size: 150px;
    --stroke-width: 20px;
    --circle-color: #f66;
    --background-color: #F2F2F2;
    --percentage: 75;
    --stroke-color-start: #EB3349;
    --stroke-color-end: #FF9381;

    width: var(--size);
    height: var(--size);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circular-progress::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: conic-gradient(var(--stroke-color-start) 0%,
            var(--stroke-color-end) calc(var(--percentage) * 1%),
            var(--background-color) 0);
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--stroke-width)), black calc(100% - var(--stroke-width)));
    mask: radial-gradient(farthest-side, transparent calc(100% - var(--stroke-width)), black calc(100% - var(--stroke-width)));
}

.progress-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1F1F1F;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.progress-title {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #4B4B4B;
}

.salesCycleTitleInFinancialMetricsForBenchmark {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    margin-top: 20px;
    margin-bottom: 20px;
}

.monthlyRevenueContainerInFinancialMetricsForBenchmarks {
    background-color: #F7F9FB;
    margin-top: 20px;
    width: 75%;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 20px;
}

.monthlyRevenueTitleWithinTheContainerForFinancialMetricsForBenchmarks {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.monthlyRevenueValueWithinTheContainerForFinancialMetricsForBenchmarks {
    padding-bottom: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #4B4B4B;
}

.breakEvenTimlineForFinancialMetricsForBenchmarks {
    margin-top: 30px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    width: 90%;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 3px 0px #0000000F;
    justify-content: space-between; /* Pushes child elements to the left and right */
}

.breakEvenTimlineForFinancialMetricsForBenchmarksTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #4B4B4B;
}

.breakEvenTimlineForFinancialMetricsForBenchmarksValue {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #4B4B4B;
    margin-top: 5px;
}

.breakEvenTimlineForFinancialMetricsForBenchmarkVisualization {
    padding-right: 15px;
    align-self: center;
    vertical-align: middle;
}

.breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainerOuterCircle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid #2948FF;
    /* Outer border gradient */
    display: flex;
    justify-content: center;
    align-items: center;
}

.breakEvenTimlineForFinancialMetricsForBenchmarkCircleContainerInnerCircle {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
    /* Inner circle background (white) */
}

.benchmarkContent {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    /* text-align: justify !important; */
    -webkit-hyphens: auto !important;
            hyphens: auto !important;
    /* overflow-wrap: break-word;  */
    /* Move long words to the next line */
    word-break: keep-all;      
    /* Prevent word breaks */
}

.benchmarkSectionContainer {
    background: #F8FCFF;
    margin-top: 40px;
    padding-top: 30px;
    padding-left: 25px;
    border-radius: 10px;
    padding-bottom: 20px;
}

.sectionTitleAndBenchmarkEvalution {
    display: flex;
    justify-content: space-between;
    /* Align items to left and right */
    align-self: center;
    /* Vertically align the elements */
}

.benchmarkSectionHeader {
    font-weight: 700;
    font-size: 24px;
    color: #3A70E2;
}

.benchmarkEvaluationRanking {
    display: flex;
    /* Align icon and text horizontally */
    align-self: center;
    width: 22%;
    margin-right: 25px;
    background-color: #FFFBFB;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px;
}

.rakingTitleAndDescription {
    padding-left: 5px;
}

.rankingTitleName {
    align-self: flex-start;
    /* Forces it to align left within a flex container */
    text-align: left !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    position: relative;
    margin-left: 0px;
}

.belowAvergaeRanking {
    border: 0.5px solid #F64C4C
}

.avergaeRanking {
    border: 0.5px solid #FFAD0D;
}

.aboveAverageRanking {
    border: 0.5px solid #47B881;
}

.rankingDescription {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.belowAvergaeRankingDescription {
    color: #F64C4C;
}

.avergaeRankingDescription {
    color: #FFAD0D;
}

.aboveAverageRankingDescription {
    color: #47B881;
}

.defaultTextColor {
    color: #4B4B4B;
}

.tamAndSamHeader {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #4B4B4B;
}

.tamAndSamContentContainer,
.marketOpportunityGraphContainers {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 30px;
}

.anvlistContainer,
.industryStandardContainer {
    background-color: #FFFFFF;
    padding: 10px;
    width: 40%;
    height: -moz-fit-content;
    height: fit-content;
}

.anvilistTitle,
.industryStandardTitle {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.tamContent,
.samContent {
    display: flex;
}

.tamContentKey,
.samContentKey {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.tamContentDescription,
.samContentDescription {
    margin-left: 5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.totalAddressableMarketGraphContainer,
.servicableAddresableMarketGraphContainer {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 20px 0px #CFCFCF2B;
    padding: 10px;
    width: 45%;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    /* Important for percentage height */
    border-radius: 10px;
}

.totalAddressableMarketGraphContainerTitle,
.servicableAddresableMarketGraphContainerTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding: 10px;
}

.legendForTamAndSamContent {
    padding-left: 25px;
}

.industryStandardLegend,
.companyLegend {
    display: flex;
    align-self: center;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: 10px;
}

.colorBar {
    width: 25px;
    height: 1px;
    border-radius: 10px;
    padding: 2px;
    align-self: center;
    position: relative;
    margin-top: 3px;
}

.industryStandardColor {
    background-color: #FDB52B;
}

.companyColor {
    background-color: #47B881;
}

.industryStandardColorTAM {
    background-color: #00B0E8;
}

.companyColorTAM {
    background-color: #F66CD7;
}

.legendText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
}

.rankingInformation {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.howToGetThereContentContainer {
    margin-bottom: 20px;
}

.howToGetThereHeader {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1F1F1F;
    margin-top: 30px;
}

.howtoGetThereRecommendationsCardContainer {
    background: #FFFFFF;
    padding: 10px;
    margin-top: 20px;
    width: 85%;
    border-radius: 10px;
}

.howtoGetThereRecommendationsTextDescriptionPoint,
.howtoGetThereRecommendationsStepArrowIcon {
    padding: 10px;
}

.howToGetThereRecommendationPointIndex {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding-left: 10px;
}

.howtoGetThereRecommendationsTextDescriptionPoint {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.anvlistAndindustryStandardContentContainer {
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    margin-top: 30px;
}

.competitorsDescriptionContent,
.differentiatorsDescriptionContent {
    display: block;
    /* Make the container block-level */
    width: 100%;
    /* Let the container take full width */
    margin-top: 20px;
}

.competitorsContentKey,
.differentiatorsContentKey {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;

    display: inline-block;
    /* Allow the label to stay inline with the content */
    white-space: nowrap;
    /* Prevent the label from wrapping */
    margin-right: 5px;
    /* Add a small gap between label and content */
}

.differentiatorsContentDescription {
    margin-left: 1px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    display: inline;
    /* Keep the value inline with the label */
    word-break: break-word;
    /* Break the text only if it's too long */
}

.competitorsContentDescription {
    margin-left: 1px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4B4B4B;
    display: inline;
    /* Keep the value inline with the label */
    word-break: break-word;
    /* Break the text only if it's too long */
}
.assessment-report-detail-tabs-container {
    width: 100%;
    border-radius: 20px;
    background-color: #F5F5F5;
    /* font-family: "Avenir Next"; */
}

.assessment-report-detail-tab.active {
    background-color: #FFFFFF;
    font-weight: 600;
    /* font-family: "Avenir Next"; */
}

.assessment-report-detail-tab {
    font-size: 16px;
    font-weight: 400;
    color: #4B4B4B;
}

.section-title {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 5px;
    margin-top: 30px;
    /* font-family: "Avenir Next"; */
}

.sectionTitleBottomLine {
    border-bottom: 0.5px solid #CACACA
}

.section-content {
    margin-top: 10px;
    /* font-family: "Avenir Next"; */
}

.analysisTitle {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.analysis-text,
.section-estimates {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    /* text-align: justify; */
}

.analysis-text {
    /* font-family: "Avenir Next"; */
}

.section-estimates {
    display: flex;
    /* font-family: "Avenir Next"; */
    /* justify-content: space-between; */
}

.blue-box-container {
    width: -moz-fit-content;
    width: fit-content;
    background-color: #D7F5FF;
    padding: 15px;
    border-radius: 10px;
    margin-right: 2%;
}

.blue-box-title {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 12px;
    line-height: 16.39px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.blue-box-description {
    color: #1F1F1F;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.green-box-container {
    width: -moz-fit-content;
    width: fit-content;
    background-color: #DDFFE9;
    padding: 15px;
    border-radius: 10px;
    margin-right: 2%;
}

.green-box-title {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 12px;
    line-height: 16.39px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.green-box-description {
    color: #1F1F1F;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* margin-bottom: 10px; */
    /* font-family: "Avenir Next"; */
}

.yellow-box-container {
    width: -moz-fit-content;
    width: fit-content;
    background-color: #FFFBD1;
    padding: 15px;
    border-radius: 10px;
}

.yellow-box-title {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 12px;
    line-height: 16.39px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.yellow-box-description {
    color: #1F1F1F;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.white-box-container {
    width: 21%;
    background-color: white;
    border: 0.5px solid #E1E1E1;
    padding: 15px;
    border-radius: 10px;
    margin-right: 2%;
}

.white-box-title {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 12px;
    line-height: 16.39px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.white-box-description {
    color: #1F1F1F;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    /* font-family: "Avenir Next"; */
}

.strengthAndWeaknessTable {
    width: 100%;
    display: flex;
    /* text-align: justify; */
    margin-top: 30px;
}

.strengthTableColumn,
.weaknessTableColumnm {
    width: 50%;

}

.strengthTableColumnTitle,
.weaknessTableColumnmTitle {
    color: #4B4B4B;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 5px;
    /* margin-top: 30px; */
    margin-bottom: 10px;
}

.weaknessTableColumnmTitle {
    padding-left: 20px;
}

.strengthBorderLine,
.weaknessBorderLine {
    border-bottom: 0.5px solid #CACACA;
}

.strengthTableColumn {
    border-right: 0.5px solid #CACACA;

}

.strengths {
    padding-right: 10px;
    display: flex;
    flex-direction: column;
}

.weakness {
    padding-left: 20px;
}

.strengths,
.weakness {
    /* font-family: "Avenir Next"; */
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
}

.listOfRecommendations {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    /* text-align: justify; */
    /* font-family: "Avenir Next"; */
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.recommendationItemTitle {
    font-size: 16px;
    /* This is an example for H4 size, adjust based on your design */
    font-weight: 600;
    /* Medium weight */
    line-height: 1.4;
    /* Optional: adjust for better spacing */
}

.recommendationConclusion {
    margin-top: 20px;
}
.generateADocumentButton {
    display: flex;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 15px;
    background-color: #4B4B4B;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: white;
    cursor: pointer;
    /* font-family: "Avenir Next"; */
}

.hiddenButton {
    margin-bottom: 4.3rem;
}

.generateADocumentButton:hover {
    background-color: #FFD152 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: white !important;
    cursor: pointer;
}

.listOfAssessmentReportSections {
    position: fixed;
    background-color: #F7F9FB;
    /*width: 70%;*/
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
    padding: 2%;
}

.inActiveNavigationItem {
    /* font-family: "Avenir Next"; */
    font-size: 12px;
    font-weight: 400;
    line-height: 16.39px;
    color: #1F1F1F;
    padding-left: 0px;
    transition: all 0.3s ease;
    /* Smooth transition for color and border changes */
}

.activeNavigationItem {
    /* font-family: "Avenir Next"; */
    font-size: 12px;
    font-weight: 500;
    line-height: 16.39px;
    color: #3B82F6;
    border-left: 2px solid #3B82F6;
    position: relative;
    padding-left: 8px;
}

.actionsContainer {
    font-family: 'AvenirNextLTPro', sans-serif !important;
}
.completePageContainerForTheAssessmentReportView {
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Avenir', Arial, sans-serif;
}

.mainContentBelowTheNavbarForTheAssessmentReportView {
    margin-top: 75px;
    /* Adjust according to the Navbar height */
    /* padding: 10px; */
    /* overflow-y: auto; */
    height: calc(100vh - 75px);
    /* Adjust according to the Navbar height */
    display: flex;
    font-family: 'Avenir', Arial, sans-serif;
}

.assessmentReportDetailContainer {
    /* margin-top: 30px; */
    width: 65%;
    margin-left: 5%;
    /* margin-right: 5%; */
    padding: 20px;
    /* background-color: #f0f0f0; */
    /* border-right: 2px solid #ccc; */
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    font-family: 'Avenir', Arial, sans-serif;
}


.assessmentReportActionContainer {
    width: 30%;
    padding: 20px;
    /* margin-top: 30px; */
    font-family: 'Avenir', Arial, sans-serif;
}
.blogTile {
    flex: 1 1;
    /* margin: 0 10px; */
    width: 60%;
    max-width: 60%;
    /* Ensure four tiles fit in a row */
}

.defaultBlogTileMarginRight {
    margin-right: 5%;
}

.lastBlogTileMarginRight {
    margin-right: 0;
}

.blogImageWrapper {
    width: 100%;
    height: auto;
    overflow: hidden;
    /* Ensure the image stays within the wrapper bounds */
    position: relative;
    border-radius: 10px;
    /* Apply rounded corners */
}

.blogImage {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
    /* Smooth zoom transition */
    object-fit: cover;
    /* Ensure the image fills the wrapper without distortion */
    border-radius: 10px;
    /* Apply rounded corners */
}

.blogImageWrapper:hover .blogImage {
    transform: scale(1.1);
    /* Zoom the image */
}

.blogDate {
    margin-top: 5px;
    font-size: 10px;
    color: darkgrey;
}

.blogTitle {
    margin-top: 5px;
    padding-right: 5px;
    font-size: 18px;
    font-weight: 700;
    width: 88%;
}

.blogReadMore {
    display: inline-flex;
    align-items: center;
    /* Vertically center the text and icon */
    /* justify-content: flex-start; */
    position: relative;
    /* Use relative positioning to align icons */
    /* Ensure the text and icon are left-aligned */
    grid-gap: 5px;
    gap: 5px;
    /* Space between the text and the plus icon */
    margin-top: 10px;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}

.blogReadMoreText {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    /* Remove any top margin */
    display: inline-block;
    position: relative;
    /* Ensure the text stays inline with the icons */
}

/* Set default visibility for both icons */
.plusIcon {
    opacity: 1;
}

.arrowIcon {
    opacity: 0;
    /* Position it over the plusIcon */
}

.readMorePlusIcon,
.readMoreArrowIcon {
    width: 14px;
    height: 14px;
    margin-left: 5px;
    /* Optional: add some margin if you want more space between the text and icon */
    position: absolute;
    /* Both icons will occupy the same space */
    right: -20px;
    /* Align icons next to the text */
    transition: opacity 0.3s ease;
    /* Smooth transition */
}

.blogReadMore:hover {
    cursor: pointer;
    color: darkgrey;
    transition: 0.3s ease;

    .blogReadMoreText {
        font-size: 12px;
    }

    .plusIcon {
        opacity: 0;
    }

    .arrowIcon {
        opacity: 1;
    }

}
.blogsContainer {
    width: 95%;
    margin: 0 auto;
    padding-top: 5%;
    padding-bottom: 5%;
}

.blogRow {
    display: flex;
    justify-content: flex-start;
    /* Align items to the left */
    margin-bottom: 5%;
    flex-wrap: wrap;
    /* Allow wrapping to handle dynamic content */
    /* margin-left: 22%; */
}

.blogRow:has(.blogTile:nth-child(1):last-child),
.blogRow:has(.blogTile:nth-child(2):last-child),
.blogRow:has(.blogTile:nth-child(3):last-child) {
    justify-content: flex-start;
    /* Align items to the left when fewer than 4 */
}

/* .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 10px;
} */

.pagination {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 20px;
    grid-gap: 10px;
    gap: 10px;
}

.paginationBtn {
    background-color: #d9f569;
    border-radius: 50%;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
}

.paginationBtn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pageNumber {
    cursor: pointer;
    font-size: 16px;
    padding: 5px;
    margin: 0 5px;
    color: gray;
}

.pageNumber.active {
    font-weight: bold;
    color: black;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #F7F0D4;
}

.pageNumber:hover {
    color: black;
}

.pageNumber,
.paginationBtn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50%;
}

.pageNumber,
.left-ellipsis,
.right-ellipsis {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.pageNumber:hover,
.left-ellipsis:hover,
.right-ellipsis:hover {
    background-color: #ffeb3b;
    border-radius: 50%;
    cursor: pointer;
}

.paginationBtn:hover {
    background-color: #FFAD0D;
}
.footer-container {
    background-color: #FFC936; /* Yellow background */
    padding: 50px;
    border-radius: 20px; /* Rounded corners */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
    /* margin-left: 15%; */
    width: 80%;
    margin: 0 auto;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footer-left p {
    margin: 0;
    font-size: 16px;
    color: #333;
    text-align: left;
    font-weight: bold;
    padding: 10px;
}

.footer-right {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
}

.social-icon {
    width: 24px;  /* Adjust size of the social icons */
    height: 24px; /* Ensure consistent sizing */
    cursor: pointer;
}

.social-icon:hover {
    opacity: 0.7; /* Simple hover effect */
}

.blogScreenHeader {
    /* padding: 40px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    background: linear-gradient(to right, #fdd5e5, #d4e9f9);
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.blogScreenHeaderTitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
}

.blogScreenHeaderSubtitle {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
}
html, body {
    height: 100%;
    margin: 0;
    overflow: auto; /* Ensure that the browser handles the scrolling */
}

.completePageContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* This ensures the container takes up the full height of the viewport */
}

.mainContentBelowTheNavbar {
    flex-grow: 1; /* Allows the container to grow and fill available space */
    margin-top: 45px; /* Adjust according to the Navbar height */
    padding: 20px;
}

.blogScreenContainer {
    display: flex;
    flex-direction: column;
}

.subscriptionPlansContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;

    /* height: 100vh; */
    /* Optional: if you want it vertically centered as well */
}

.affordableSubscriptionPricingPlan,
.premiumSubscriptionPricingPlan {
    margin: 0 20px;
    /* Space between the two plans */
}

.pricingPlansHeader {
    font-size: 36px;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    color: #4b4b4b;
    display: inline-block;
    /* Makes the border take the width of the text */
    position: relative;
    padding-bottom: 10px;
    /* Adds space between text and border */
    border-bottom: 2px solid black;
    /* Adds the border */
}

.subscriptionPlansForVentures {
    margin-top: 2%;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    color: #4b4b4b;
}

.choosePlanText {
    margin-top: 1%;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    color: #4b4b4b;
    margin-bottom: 3%;
}

.subscriptionPricingIcon {
    position: relative;
    width: 20px;
    /* Resized width */
    height: 20px;
    /* Resized height */
    border-radius: 50%;
    /* Creates a circle */
    background: transparent;
    /* Transparent background */
    border: 2px solid #4caf50;
    /* Circle border color */
    display: inline-block;
    box-sizing: border-box;
}

.subscriptionPricingIcon::after {
    content: "";
    position: absolute;
    width: 5px;
    /* Checkmark width resized */
    height: 12px;
    /* Checkmark height resized */
    border: solid #4caf50;
    border-width: 0 2px 2px 0;
    /* Defines the checkmark style, adjusted for smaller size */
    transform: rotate(45deg);
    /* Rotates the border to form a checkmark */
    top: 1px;
    /* Moves checkmark down within the smaller circle */
    left: 8px;
    /* Moves checkmark horizontally to the right */
    z-index: 2;
    /* Ensures checkmark is on top */
}

/* Creating the small gap in the top-right part of the circle */
.subscriptionPricingIcon::before {
    content: "";
    position: absolute;
    top: -2px;
    /* Slightly outside the circle */
    right: -2px;
    /* Slightly outside the circle */
    width: 5px;
    /* Resized width of the gap */
    height: 5px;
    /* Resized height of the gap */
    background-color: white;
    /* To hide part of the circle */
    z-index: 1;
    /* Ensures it stays on top of the border */
    border-radius: 50%;
    /* Rounds the small gap */
}

.subscriptionPricingPlanNameWithIcon {
    display: flex;
    justify-content: center;
    /* Center the items inside this div horizontally */
    align-items: center;
    text-align: center;
}

.subscriptionPricingBenefit {
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    color: #4b4b4b;
    margin-left: 10px;
}

.subscriptionPricingPlanIcon {
    width: 16px;
    height: 16px;
}

.subscriptionPricingPlanName {
    margin-top: 5%;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    color: #4b4b4b;
}

.pricingCategories {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    width: 55%;
    /* Set the width to 40% of its container */
    margin: 0 auto;
    align-items: center;
    /* Center the block horizontally */
    padding-left: 6%;
}

.subscriptionPricingPlanCardContainer {
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    height: 62vh;
    position: relative; 
}

.subscriptionPricingPlanDescription {
    margin-top: 3%;
    font-size: 14px;
    font-weight: 600;
    color: darkgrey;
    text-align: justify;
    line-height: 1.6; /* This is a typical value, but you can adjust it based on your needs */
}

.subscriptionPricingPlanPriceValue {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    margin-top: 5%;
}

.subscriptionPricingPlanPriceValuePerMonthOrYearText {
    font-size: 12px;
    line-height: 12px;
    color: darkgrey;
    margin-top: 4%;
}

.additionalMarginTopForYearlyCost {
    margin-top: 10%;
}

.subscriptionPricingPlanBenefitsList {
    margin-top: 10%;
    align-items: center;
}

.subscriptionPricingPlanBenefitItem {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    align-items: center; /* Aligns items vertically in the center */
    grid-gap: 5px;
    gap: 5px; /* Space between the blue box and the text */
}

.subscriptionPricingPlanBenefitItemBlueBox {
    width: 18px;
    /* Adjust size as needed */
    height: 18px;
    /* Adjust size as needed */
    background-color: #007BFF;
    /* The blue color */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    /* Rounded corners */
}

.subscriptionPricingPlanBenefitItemBlueBoxCheckmark {
    color: white;
    font-size: 10px;
    /* Adjust size as needed */
}

.subscriptionPricingPlanBenefitItemName {
    font-size: 14px; /* Adjust font size as needed */
    color: #333; /* Dark gray text color */
    font-weight: 500;
}

.subscribeToAPlanButton {
    position: absolute; /* Position the button absolutely within its container */
    bottom: 20px; /* 20px from the bottom */
    margin-top: 10%;
    background-color: #F2C41E;
    border-radius: 5px;
    border: 3px solid black;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    width: 83%;
    cursor: pointer;
}

.additionalMarginTopForBenefitItem {
    margin-top: 2%;
}

/* .premiumSubscriptionPricingPlan {
    position: relative;
    margin-top: -3%;
} */
.meetingsContainerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.meetingsBackButtonImage {
    width: 18px;
    height: 18px;
}

.backAndSearchContainer {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.meetingsBackButtonText {
    color: #000000;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 24.2px;
}

.backButtonForMeetingsContainer {
    display: flex;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
    cursor: pointer;
}

.searchContainerForMeetingsContainer {
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    padding: 8px;
    border-radius: 15px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    transition: all 0.3s ease;
}

/* Styling for the search input */
.meetings-container-search-input {
    border: none;
    outline: none;
    padding: 5px;
    padding-left: 25px;
    font-size: 16px;
    border-radius: 15px;
    width: 359px;
    /* height: 48px; */
    transition: width 0.3s ease;
    background-color: #F5F5F5;
}

/* Responsive input expansion on focus */
/* .meetings-container-search-input:focus {
    width: 300px;
} */

/* Hover effect for container */
/* .searchContainerForMeetingsContainer:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
} */

/* Styling the placeholder text */
.meetings-container-search-input::placeholder {
    color: #000000;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
}

.newMeetingsButton {
    width: 169px;
    background-color: #F2C41E;
    border-radius: 20px;

    color: #FFFFFF;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .meetingsContainerHeader {
        flex-direction: column;
        align-items: center;
        margin: 3% 3%;
    }

    .newMeetingsButton,
    .backAndSearchContainer {
        width: 100%;
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    .meetingsContainerHeader {
        margin: 2% 2%;
    }

    .meetingsBackButtonText,
    .meetings-container-search-input {
        font-size: 14px;
    }
}
.meetingsCardContainer {
    display: flex;
    /* justify-content: space-between; */
    /* Distribute the items with space in between */
    align-items: center;
    /* Align items vertically at the center */
    margin-top: 1.5%;
    margin-left: 3%;
    margin-right: 3%;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    background: #FFFDFE;
    border-radius: 10px;
}

.meetingTimeColumn {
    flex-grow: 0;
    width: 12%;
    max-width: 12%;
    align-items: center;
    justify-content: center;
    margin-right: 3%;
    padding: 0 10px;
}

/* Limit the width of the meetingTitleColumn and handle text overflow */
.meetingTitleColumn {
    flex-grow: 0;
    width: 30%;
    max-width: 30%;
    margin-right: 4%;
    overflow: hidden;
    /* Ensure overflow is hidden */
    text-overflow: ellipsis;
    /* Show ellipsis when text exceeds the width */
    white-space: nowrap;
    /* Prevent text wrapping */
    align-items: center;
}

.meetingCompanyNameColumn {
    flex-grow: 0;
    width: 15%;
    max-width: 15%;
    margin-right: 3%;
    overflow: hidden;
    /* Ensure overflow is hidden */
    text-overflow: ellipsis;
    /* Show ellipsis when text exceeds the width */
    white-space: nowrap;
    /* Prevent text wrapping */
    align-items: center;
}

.meetingParticipantsNamesColumn{
    flex-grow: 0;
    width: 18%;
    max-width: 18%;
    margin-right: 3%;
    overflow: hidden;
    /* Ensure overflow is hidden */
    text-overflow: ellipsis;
    /* Show ellipsis when text exceeds the width */
    white-space: nowrap;
    /* Prevent text wrapping */
    align-items: center;
}

.meetingTimeColumn {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    color: #000000;

    background: #F8F2DB;
    border-radius: 10px;
    padding-top: 1%;
    padding-bottom: 1%;
}

.meetingTitleColumn,
.meetingParticipantsNamesColumn {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: #000000;
}

.meetingCompanyNameColumn {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
    color: #000000;
}

.meetingActionButtons {
    flex-grow: 0;
    width: 100px;
}

.meetingActionButtons {
    /* width: 169px; */
    background-color: #F2C41E;
    border-radius: 20px;

    color: #FFFFFF;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.52px;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .meetingsCardContainer {
        padding-left: 4%;
        padding-right: 4%;
    }
}

@media (max-width: 768px) {
    .meetingsCardContainer {
        flex-direction: column;
        padding: 2%;
    }
    .meetingTimeColumn,
    .meetingTitleColumn,
    .meetingCompanyNameColumn,
    .meetingParticipantsNamesColumn,
    .meetingActionButtons {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .meetingTimeColumn,
    .meetingTitleColumn,
    .meetingCompanyNameColumn,
    .meetingParticipantsNamesColumn {
        font-size: 14px;
    }
}


.meetingCardsDetailContainer {
    display: flex;
    flex-direction: column;
}

.headerRow {
    display: flex;
    padding: 10px 3%;
    /* Match the padding of your card columns */
    margin: 2% 3%;
    /* Ensure it aligns with the card margins */
    position: relative;
    margin-bottom: -1.5%;
}

.headerTimeColumn,
.headerTitleColumn,
.headerCompanyNameColumn,
.headerParticipantsNamesColumn {
    flex-grow: 1;
    padding: 0 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    color: #000000;
    font-family: 'AvenirNextLTPro', sans-serif !important;
}

/* Specific column adjustments to match the card styles */
.headerTimeColumn {
    flex-grow: 0;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-right: 11%;
}

.headerTitleColumn {
    flex-grow: 0;
    /* Adjust based on your preference for title width */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 30%;
    max-width: 30%;
    margin-right: 2%;
}

.headerCompanyNameColumn {
    flex-grow: 0;
    width: 15%;
    max-width: 15%;
    margin-right: 1%;
}

.headerParticipantsNamesColumn {
    flex-grow: 0;
}

@media (max-width: 768px) {
    .headerRow {
        flex-direction: column;
        padding: 10px 5%;
        margin: 1% 5%;
        /* Adjust margins to align with card containers */
    }

    .headerTimeColumn,
    .headerTitleColumn,
    .headerCompanyNameColumn,
    .headerParticipantsNamesColumn {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
    }
}

@media (max-width: 480px) {
    .headerRow {
        padding: 10px 2%;
        margin: 1% 2%;
    }

    .headerTimeColumn,
    .headerTitleColumn,
    .headerCompanyNameColumn,
    .headerParticipantsNamesColumn {
        font-size: 12px;
    }
}
.meetingsListContainer {
    margin-top: 1.5%;
    background: #F5F5F5;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.listOfMeetingCards {
    max-height: 65vh;
    overflow-y: scroll;
}

.emptyCardSpace {
    margin-top: 2%;
}

.emptyTopSpace {
    margin-top: 1%;
}

.noMeetingsListView {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-top: 10%;
    padding-bottom: 10%;
    font-size: 24px;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
}

@media (max-width: 1024px) {
    .meetingsListContainer {
        margin-left: 4%;
        margin-right: 4%;
    }
}

@media (max-width: 768px) {
    .meetingsListContainer {
        margin-left: 3%;
        margin-right: 3%;
    }
}

@media (max-width: 480px) {
    .meetingsListContainer {
        margin-left: 2%;
        margin-right: 2%;
    }
}
.meeting-modal-container {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 20px;
}

.complete-meeting-modal-container {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.meetingEditButton {
    display: flex;
    align-self: end;
    background-color: #F2C41E;
    width: 170px;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 20px;
    color: #FFFFFF;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    cursor: pointer;
}

.meetingTitleLabel {
    color: #000000;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    align-items: center;
    margin-bottom: 10px;
}

.meetingTitleInputElement {
    background-color: #F1F1F1;
    border-radius: 5px;
    width: 428px;
    height: 25px;
    padding: 5px 10px;
    border: none;
}

.inviteParticipantsLabel {
    margin-top: 4%;
    color: #000000;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    align-items: center;
    margin-bottom: 10px;
}

.input-text-area-for-list-of-emails {
    width: 613px;
    border-radius: 5px;
    background-color: #F1F1F1;
}

.listOfOtherMeetingDetails {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 4%;
    grid-gap: 10%;
    gap: 10%;
}

.listOfOtherMeetingDetailsTextInput {
    width: 227px;
    border-radius: 5px;
    background-color: #F1F1F1;
    height: 25px;
    padding: 5px 10px;
    border: none;
}

.dateOfMeetingLabel,
.meetingStartTimeLabel,
.meetingStopTimeLabel {
    color: #000000;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    align-items: center;
    margin-bottom: 5px;
}

.meetingDescriptionLabel {
    margin-top: 4%;
    color: #000000;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    align-items: center;
    margin-bottom: 10px;
}

.input-text-area-for-meeting-description {
    /* width: 783px; */
    border-radius: 5px;
    background-color: #F1F1F1;
}

.meetingModalEditViewActionButtons {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}

.meetingSaveButton {
    display: flex;
    background-color: #F2C41E;
    width: 170px;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 20px;
    color: #FFFFFF;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    cursor: pointer;
}

.meetingCancelButton {
    display: flex;
    background-color: #FAFAFB;
    width: 170px;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid #000000;
    border-radius: 20px;
    color: #9E99A6;
    font-weight: 700;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    cursor: pointer;
}

.meetingTitleValueInViewMode,
.listOfEmailsValueInViewMode,
.listOfOtherMeetingDetailsValueInViewMode,
.meetingDescriptionInputValueInViewMode {
    display: flex;
    color: #000000;
    font-weight: 400;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 16px;
    line-height: 19.36px;
    align-items: center;
}

.listOfOtherMeetingDetailsValueInViewMode {
    width: 145px;
}

.meetingDescriptionInputValueInViewMode {
    text-align: justify;
}

.searchContainerForProfessionalsContainer {
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    padding: 8px;
    border-radius: 5px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    transition: all 0.3s ease;
    width: 613px;
}

.professionals-search-input {
    border: none;
    outline: none;
    padding: 5px;
    padding-left: 25px;
    font-size: 16px;
    border-radius: 5px;
    width: 359px;
    /* height: 48px; */
    transition: width 0.3s ease;
    background-color: #F5F5F5;
}

/* Styling the placeholder text */
.professionals-search-input::placeholder {
    color: #000000;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
}

.added-professional-names-container {
    display: 'flex';
    margin-top: 10px;
    align-items: center;
}

.added-professional-names-wrapper {
    display: 'flex';
    grid-gap: '8px';
    gap: '8px';
    flex-wrap: 'wrap';
}

.added-professional-tag {
    display: inline-flex;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    /* Make it the positioning reference for the close button */
    background-color: #f1f1f1;
    padding: 5px 20px;
    border-radius: 5px;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 14px;
    color: #333;
    /* Add extra space on the right for the close button */
}

/* Styling for the close (×) button */
.added-professional-tag-close-btn {
    position: absolute;
    top: -3px;
    right: -3px;

    background: #ff4d4d;
    border: none;
    color: white;
    font-size: 8px;
    margin-left: 8px;
    cursor: pointer;
    padding: 0;
    line-height: 1;
    width: 12px;
    height: 12px;
    border-radius: 8px;
}

.added-professional-tag-close-btn:hover {
    color: white;
}

.delete-modal-container {
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.delete-modal-container-text {
    margin-bottom: 5%;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
    color: #000000;
    font-family: 'AvenirNextLTPro', sans-serif !important;
}
.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    /* height: 100vh; */
    /* Full viewport height */
}

.spinner {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
}

.spinner::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: #7dcea0;
    /* Solid green line */
    animation: spin-top-bottom 1s linear infinite;
}

.loading-screen-text {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
    margin-top: 3rem;
    margin-left: 2rem;
}

.postAPIHitText {
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.05px;
}

.successText {
    color: green;
}

.failedText {
    color: red;
}


@keyframes spin-top-bottom {

    0%,
    25% {
        border-style: solid;
        transform: rotate(0deg);
    }

    50% {
        border-style: dotted;
        transform: rotate(180deg);
    }

    75%,
    100% {
        border-style: solid;
        transform: rotate(360deg);
    }
}
