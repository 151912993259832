@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

@font-face {
  font-family: 'Avenir';
  src: url('../src/fonts/AvenirNextLTPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNextLTPro';
  src: url('../src/fonts/AvenirNextLTPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body{min-height: 100vh !important; }

body, p, h1, h2, h3, h4, h5, h6, li, a, span, button, b, html, .card-title, .report-title, .tag, .meetingCompanyNameColumn, .ant-steps-item-title, div{
  font-family: 'Avenir', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container{width: 100% !important; margin: auto !important;}

.bg-white{background-color: white}

.ant-btn-primary, .ant-btn-primary:hover{
  background-color: #F2C425 !important;
  color: #4b4b4b;
}
.text-gold{color: #F2C425 !important;}
.btn{
  min-width: 80px !important;
}

.btn-white{
  background-color: #FFF !important;
  border: 1px solid #FFF !important;
  color: black;
  min-width: 200px !important;
}

.btn-white:hover{
  background-color: #F2C425 !important;
  border: 1px solid #F2C425 !important;
  color: #4b4b4b;
}

.btn-primary, .btn-primary:hover{
  background-color: #F2C425 !important;
  border: 2px solid #4b4b4b !important;
  color: #4b4b4b;
}

.btn-primary-outline{
  background-color: transparent;
  border: 1px solid #F2C425 !important;
  color: #F2C425;
}

.btn-primary-outline:hover{
  background-color: #F2C425;
  border: 1px solid #F2C425 !important;
  color: #4b4b4b;
}

.btn-primary-disabled{
  background-color: #c1c1c1 !important;
  border: 1px solid #ccc !important;
  color: #FFF !important;
  font-weight: bold;
}

.btn-dark{
  background-color: #373333 !important;
  border: 1px solid #373333 !important;
  color: #F2F2F2;
}

.btn-small{font-size: 12px; font-weight: bold}

.card-radius{border-radius: 10px}

.initials-avatar{
  width: 40px !important;
  height: 40px !important;
  background-color: #F2C425 !important;
  font-weight: bold;
}
.pricing-card{min-height: 55vh !important;}
.pricing-header{
  font-weight: bolder;
  font-size: 1.6rem;
}
.pricing-header-sub{font-size: 12px}
.pricing-list{font-size: 13px}
.pricing-detail{min-height: 20vh}

input[type="checkbox"], .ant-checkbox-wrapper {
  padding: 10px !important;
  font-size: 1rem !important;
}

/*==== Recommendation Styles ======*/
.bg-light-blue{background-color: #f0ffff}
.bg-light-yellow {
  background-color: #fff9ea
}
.max-height{min-height: 80vh}
.badge-success{color: #343e3f; background-color: #caebf1; padding: 0; font-size: 11px}

.dashboard-card{
  min-height: 38vh;
  border-radius: 15px;
}

.dashboard-card-text{min-height: 18vh}

.bg-profile-gradient{
  height: 20px;
  background-color: #00b0e8;
}

.nav-item .active{
  color: #F2C425 !important;
  font-weight: bolder;
}

.profile-sections-box-detail{
  min-height:10vh;
}

.profile-avatar-box{
  width: 150px !important;
  height: 150px !important;
  background-color: #00b0e8;
  border-radius: 50%;
  text-align: center;
}

.icon-large{font-size: 2rem}

.center-text-div {
  text-align: center;
  padding-top: 20%;
}

.file-grey-box{
  background-color: #FAFAFA;
  border-radius: 20px;
}

.dot-small-card{
  height: 25px;
  width: 25px;
  background-color: #4b4b4b;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
}

.number-circle-active{
  height: 25px;
  width: 25px;
  background-color: #6bc497;
  border-radius: 50%;
  color: #FFF;
  text-align: center;
  margin-right: 10px;
  font-size: 13px;
  padding-top: 3px;
}

.number-circle{
  height: 25px;
  width: 25px;
  /*background-color: #6bc497;*/
  border-radius: 50%;
  color: black;
  text-align: center;
  margin-right: 10px;
  font-size: 13px;
  padding-top: 3px;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-otp{width: 100%}

.ant-select{padding: 0 !important;;background-color: transparent !important;border: none !important;width: 100% !important;}
.react-tel-input > .form-control{width: 100% !important;}


@media print {
  .no-print { display: none !important; }  /* Hide buttons during PDF generation */
}

.bg-recentMessage {
  background-color: #00b0e8;
  border-radius: 20%;
  color: #FFF;
  padding: .5rem 1rem !important
}

.text-small {
  font-size: 0.7rem;
}

.text-small-message {
  font-size: 0.9rem;
}

.message-width {
  width: 90%;
  height: 80vh;
}

.message-header {
  height: 8vh;
  width: 100%;
}

.message-content {
  height: 65vh;
  width: 100%;
  overflow: scroll;
  scroll-behavior: smooth;
}

.message-actions {
  height: 12vh;
  border: 2px solid #EEE;
  border-radius: 20px;
}

.message-content::-webkit-scrollbar {
  width: 5px;
}

.message-content::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.message-card-right {
  background-color: #EEE;
  text-align: right;
  border-radius: 10px;
  width: 50%;
  margin: 0 0 0 50%;
}

.message-card-left {
  border: 2px solid #EEE;
  text-align: left;
  border-radius: 10px;
  width: 50%;
}

#message-actions-textarea {
  appearance: none;
  outline: none;
  border: none;
  background: transparent;
  resize: none;
  box-shadow: none !important;
  font-family: inherit;
  font-size: inherit;
  width: 100%;
}

.chat-input {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 5px;
}

.chat-input i {
  font-size: 17px;
  cursor: pointer;
  color: #555;
}

.chat-input i:hover {
  color: #000;
}

.text-blue {
  color: rgb(100, 131, 201);
}

.suggestedProgress .ant-progress-inner {
  width: 50px !important;
  height: 50px !important;
  font-size: 13px !important;
}

/* Remove default Bootstrap input focus styles and set custom yellow border */
.form-control {
  font-size: 0.9rem;
}

.form-control:focus,
.form-select:focus,
textarea:focus {
  border-color: #F2C425 !important;
  box-shadow: 0 0 0 0.08rem rgba(242, 196, 37, 0.5) !important;
  outline: none !important;
  font-size: 0.9rem;
}

label {
  font-size: 0.9rem;
}

@media only screen and (max-width: 600px) {
  .max-height{min-height: auto}
  .mobile-full-width{width: 100% !important;}
  .mobile-padding-top{padding-top: 8% !important;}

  .bg-recentMessage {display: none; }
  
  .chat-input { margin-top: -15px }
}